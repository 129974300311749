const locations = [
  {
    index: 0,
    name: "Startsted - utenfor hotellet",
    info: "Lykke til og god tur",
    image: "0.jpg",
    coordinates: {
	    northWest: { lat: 60.39616850393266, lon: 5.31899022171073 },
      southEast: { lat: 60.395166780011316, lon: 5.329451561622076 },
    },
  },
  {
    index: 1,
    name: "Den blå steinen",
    info: "Første oppgave er knallhard: Ikke alt i Bergen er vått og kaldt med breiflabb over alt. Hvis du skal treffe en bergenser, er det gjerne her dere møtes",
    image: "1.jpg",
    coordinates: {
	    northWest: { lat: 60.39195703, lon: 5.322845143 },
      southEast: { lat: 60.391803973, lat: 5.3229400607},
    },
  },
  {
    index: 2,
    name: "Stålskulptur av Kong Olav Kyrre",
    info: "En ridder, sønn av en vognmann fra Oslo, vant i 1969 en konkuranse om å hedre det historiske Bergen. Du skal til et omdiskutert monument som noen vil si er helt konge, mens en anmeldelse sier det \"[...] ser ut som et stålvrak\". Det er ikke Skjerjehamn i Gulen du skal til. ",
    image: "2.jpg",
    coordinates: {
	    northWest: { lat:60.3903440148, lon: 5.3300916975974559 },
      southEast: { lat: 60.39003811953955, lon: 5.3312205072912136 },
    },
  },
  {
    index: 3,
    name: "Biblioteket",
    info: "Nest største av sitt slag i Norge. Instutisjonen deler forkortelse med navn på en fartsfylt sport best kjent fra vinter- OL, Jamaica(‽), og et lokalt byggelag. Av ukjente grunner ser du alltid etter dette stedet hvis du skal lære spansk.",
    image: "3.png",
    coordinates: {
	    northWest: { lat: 60.390250464295, lon: 5.33155032228 },
      	southEast: { lat: 60.38930609, lon :5.332342723},
    },
  },
  {
    index: 4,
    name: "Byparken bybanestopp",
    info: "Før du får gå videre: Ta bilde av en bok som handler om tog eller bruer og send til Simen, 98420445. Byens fotballag er kanskje oppkalt etter en hendelse i 1855 som var foranledningen til det som er her i dag. TSI PRM og EN45545 gjelder trolig ikke på dette stedet, men det er bare fordi det ikke er ekte jernbane.",
    image: "4.jpg",
    coordinates: {
	    northWest: { lat: 60.39222083639451, lon: 5.3251247777  },
      southEast: { lat:  60.39188571943, lon: 5.3257002953 },
    },
  },
  {
    index: 5,
    name: "Det lille kaffekompaniet",
    info: "SJT driver ikke bare tilsyn med jernbane og tog. Du skal ikke til topps, men en kopp varm drikke i bunnen er inkludert. Stedet du skal til har navn etter en svart drikk og er større enn en tropp, men mindre enn en bataljon.",
    image: "5.jpg",
    coordinates: {
	    northWest: { lat: 60.396619008411, lon: 5.32889785 },
      southEast: { lat: 60.3962909381423, lon: 5.3292409933 },
    },
  },
  {
    index: 6,
    name: "Mariakirken i Bergen",
    info: "Gå inn og få deg en velfortjent kaffe to-go før du jogger videre til neste sted. Kodeordet for gratis kaffe i disken er \"ALBATROSS\". Mens dere drikker litt kaffe kan dere tenke på neste sted: Hanseatene hadde kort vei fra handel til frelse. Det er uvisst hva som skapte trøbbel i tårnet, men her har de lappet og bygd i århundrer.",
    image: "6.png",
    coordinates: {
	    northWest: { lat: 60.399265449, lon: 5.322979357 },
      southEast: { lat: 60.398579485, lon: 5.323907 },
    },
  },
  {
    index: 7,
    name: "Narvesen rundetårnet",
    info: "Du skulle tro du bare kunne kjøpe fisk her, men siden 1920 har du kunnet handle pølser på tilbud fra dette runde bygget.",
    image: "7.jpg",
    coordinates: {
	    northWest: { lat: 60.39557078, lon: 5.3255860523 },
      southEast: { lat: 60.39539041985, lon: 5.3258262963 },
    },
  },
  {
    index: 8,
    name: "Game bar",
    info: "Nå er du nært målet! Herren husker. I alle fall før han har vært her en hel kved. Du skal til andre etasje hvor du vil bli belønnet for din innsats med mat og lek. ",
    image: "8.gif",
    coordinates: {
      northWest: { lat: 60.364774, lon: 5.350897 },
      southEast: { lat: 60.36660132643476, lon: 5.352780151192839 },
    },
  },
];

export default locations;
